<template>
<div class="ma-4 pa-3">
    
    <v-card>
        <v-toolbar
      color="primary"
      dark
      flat
    >
    <v-img
    class="mx-2"
    src="../assets/socialgeniuslogo.png"
    max-height="40"
    max-width="40"
    contain
    
  ></v-img>
<v-toolbar-title >Contact and Support</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-avatar size="48">
      <img src="https://yt3.ggpht.com/O3k22rMrvX6e9nb3b5QJw-NFhkznVMqEoUyyPETS1bvNDRxWSoCwORGX7c_-P4sYa6E3l05Ohw=s240-c-k-c0x00ffffff-no-rj" alt="hackmakemod">
    </v-avatar>
</v-toolbar>
        <v-card-text>
            <h2 class="primary--text">Before you proceed:</h2>
            <p>Have you installed all the right drivers and boards for your project? A more detailed <a class="primary--text"
              style="text-decoration: none" target="_blank" href="https://hackmakemod.com/blogs/projects/diy-kit-guide-building-your-own-subscription-clock">blog post</a>  can be found on HackMakeMod's site.</p>
                <v-btn block dark link target="_blank" color="primary lighten-2 mb-4 mt-4" href="https://hackmakemod.com/blogs/projects/diy-kit-guide-building-your-own-subscription-clock">Go to the Blog Post</v-btn>
            <h3>Boards:</h3>
            <p>https://arduino.esp8266.com/stable/package_esp8266com_index.json</p>
            <h3>Drivers:</h3>
            <p><a class="primary--text"
              style="text-decoration: none" href="https://github.com/nodemcu/nodemcu-devkit/tree/master/Drivers"> https://github.com/nodemcu/nodemcu-devkit/tree/master/Drivers</a></p>
            <h3>Libraries:</h3>
            <p>
                <ul>
                    <li>ArduinoJson.h</li>
                    <li>MD_MAX72xx.h</li>
                    <li>MD_Parola.h</li>
                    <li>NTPClient.h</li>
                    <li>WiFiUdp.h</li>
                </ul>
            </p>
            <h2 v-if="hasActiveProject">Your project setup:</h2>
            <v-card  v-if="hasActiveProject">
                <v-card-text>
                    <h3 class="primary--text mb-0">Project Name: {{activeProject.projectName}}</h3>
                    <p>{{activeProject.projectBoard.name}}</p>
                    <v-list tile dense>
                        <v-subheader color="primary">Pins</v-subheader>
                        <v-list-item> Digital Data: {{activeProject.projectBoard.pins.digital}}</v-list-item>
                        <v-list-item> Clock: {{activeProject.projectBoard.pins.clk}}</v-list-item>
                        <v-list-item> CS: {{activeProject.projectBoard.pins.cs}}</v-list-item>
                    </v-list>
                    <p>Also! make sure you double check the spelling of your Wifi SSID and password in the code. Your project may not be on your network!</p>
                </v-card-text>
            </v-card>
            <v-alert
    v-model="emailSent"
  dismissible
  elevation="5"
  type="success"
>Thank you! Your email to Social Genius Support has been sent successfully.</v-alert>
            <v-btn block dark color="primary lighten-2 mb-4 mt-4" @click="showForm = true">Send Us an Email</v-btn>
        </v-card-text>

        <v-expand-transition>
                  <v-card
                    v-if="showForm"
                    class="transition-fast-in-fast-out v-card--reveal overflow-y-auto"
                    style="height: 100%"
                  >
                    <v-card-text class="pb-0">
                      <p class="text-h5 text--primary">Support Form</p>
                      <p
                        class="red--text darken-2 pa-0 ma-0"
                        style="margin-top: -15px !important"
                      >
                        
                      </p>
                      <p>We try to respond within 24 hours :)</p>
                      <v-form>
                        <div class="form-group  mt-6">
                          <v-text-field
                            
                            placeholder="you@gmail.com"
                            label="Your email address"
                            hide-details="auto"
                            :value = "getUser.email"
                            disabled
                            ><v-icon slot="prepend" color="green darken-2">
                              mdi-email
                            </v-icon></v-text-field
                          >
                        </div>
                         <div class="form-group mb-4 mt-6" v-if="hasActiveProject">
                        <v-select
        v-model="selectedHardware"
        :items="boards"
        hint="We got this from your active project. Is this the bnoard you're using?"
        item-text="name"
        label="Select a board"
        persistent-hint
        return-object
        single-line
        solo
        class="pa-3"
      ></v-select>
                         </div>
                        <div class="form-group mb-4 ">
                          <v-textarea
                          v-model="compileError"
                            placeholder="This error is annoying! {paste here}"
                            label="I'm getting this error when I compile:"
                            hide-details="auto"
                            counter
                            ></v-textarea
                          >
                        </div>
                         <div class="form-group mb-4 ">
                          <v-textarea
                            v-model="supportMessage"
                            placeholder="I just want to tell you, you're awesome!"
                            label="Message"
                            hide-details="auto"
                            counter
                            ></v-textarea
                          >
                        </div>
                      </v-form>
                    </v-card-text>
                    <v-card-actions class="pt-0">
                      <v-btn
                        text
                        color="primary accent-4 ml-4"
                        @click="
                          cancelForm
                        "
                      >
                        Cancel
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="primary accent-4 lighten-2 mr-6" @click="sendEmail">
                        Send
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-expand-transition>
    </v-card>
</div>
</template>
<script>
import { mapGetters } from "vuex";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
const axios = require("axios");

export default {
  name: "Contact",
  data: () => ({
    showForm: false,
    emailSent: false,
    compileError: '',
    supportMessage: '',
    selectedHardware: {},
    hasActiveProject: false,
    boards: [],
    activeProject: null,
  }),
  computed: {
    ...mapGetters(["getUser"]),
  },
  methods: {
      cancelForm() {
          this.compileError = ''
            this.supportMessage = ''
            this.showForm = false;
      },
    async sendEmail() {
        console.log(this.activeProject)
        const message = {
            email: this.getUser.email,
            project: this.activeProject,
            compileError: this.compileError,
            message: this.supportMessage
        }
        var config = {
            method: "post",
            url: `${process.env.VUE_APP_API_BASE_URL}/v1/utils/email/support`,
            data: {message}
          };
          const response = await axios(config);
        if(response){
            this.compileError = ''
            this.supportMessage = ''
            this.showForm = false;
            this.emailSent = true;
        }
      
    },
  },
  async created() {
    var store = firebase.firestore();
    
    const integrations = await store
      .collection("integrations")
      .where("active", "==", true)
      .get();
    await integrations.forEach((doc) => {
      this.boards.push(doc.data());
    });
    const project = await store
      .collection("projects")
      .doc(this.getUser.uid)
      .get();
    this.activeProject = await project.data();
    if (this.activeProject.projectBoard) {
      for (let i = 0; i < this.boards.length; i++) {
        if (this.boards[i].name == this.activeProject.projectBoard.name) {
          this.selectedHardware = this.boards[i];
           this.hasActiveProject = true
        }
      }
    } else {
      this.hasActiveProject = false
      this.selectedHardware = this.boards[0];
    }
  },
};
</script>
<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>